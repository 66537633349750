import React, { ChangeEvent, MouseEvent, useState } from 'react';
import './App.css';

interface Counter {
  count: number
  name: string
}

const App:React.FC = () => {
  const [counters, setCounters] = useState<Counter[]>([]);
  const [name, setName] = useState('');
  const handleButtonAdd = () => {
    setCounters(prev => ([
      ...prev,
      {
        count: 0,
        name
      }
    ]));
    setName('')
  }
  const handleButtonCount = (event: MouseEvent, counter:Counter) => {
    event.preventDefault();
    const { type } = event;
    const count = type === 'click' ? counter.count+1 : counter.count-1;
    setCounters(counters.map(_counter => _counter === counter ? {...counter, count} : _counter))
  }
  const handleChangeNameInput = (event: ChangeEvent<HTMLInputElement>) => setName(event.currentTarget.value);
  const handleShowInformations = () => alert('Left click to increment\nRight click to decrement');
  return (
    <>
      <header>
        <h1 className='header-title'>Counter</h1>
        <div>
          <input
            type="text"
            name="name"
            id="name"
            onChange={handleChangeNameInput}
            value={name}
            onKeyDown={event => event.key === 'Enter' && handleButtonAdd()}
          />
          <button className='header-button-add' onClick={handleButtonAdd}>Add</button>
          <button className="header-button-info" onClick={handleShowInformations}>ℹ</button>
        </div>
      </header>
      <main>
        {counters.map((counter, index) => (
          <div className='counter-item' key={index}>
            <p
              className='counter-item-count'
              onClick={event => handleButtonCount(event, counter)}
              onContextMenu={event => handleButtonCount(event, counter)}>
              {counter.count}
            </p>
            <p>{counter.name}</p>
          </div>
        ))}
      </main>
    </>
  );
}

export default App;
